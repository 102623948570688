import {utils} from "./utils";

/**
 * Scroll smoothly the page towards the given element
 * @param elem
 * @param offset
 */
export function goToByScroll(elem, offset) {
	if (elem === null)
	{
		return false;
	}

	if (typeof offset === 'undefined')
	{
		offset = 0;
	}

	// close mobile menu
	document.body.classList.remove('menu-open');
	let menuToggle = document.getElementById('menu-toggle')

	if (menuToggle !== null)
	{
		menuToggle.classList.remove('tcon-transform');
	}

	let offsetPosition = utils.getOffset(elem).top + parseInt(offset);

	window.scrollTo({
		top:      offsetPosition,
		behavior: "smooth"
	});
}