import {goToByScroll} from './go-to-by-scroll';
import {utils} from './utils';
import {bindLinkToItem} from './bind-link-to-item';
//import { gsap } from '../libs/gsap.min';
//import { ScrollTrigger } from '../libs/ScrollTrigger.min';

let prevWindowWidth = window.innerWidth;

gsap.registerPlugin(ScrollTrigger);

let gsapMatchmedia = gsap.matchMedia();

// Initialize a new Lenis instance for smooth scrolling
const lenis = new Lenis();

// Listen for the 'scroll' event and log the event data to the console
/*lenis.on('scroll', (e) => {
	console.log(e);
});*/

// Synchronize Lenis scrolling with GSAP's ScrollTrigger plugin
lenis.on('scroll', ScrollTrigger.update);

// Add Lenis's requestAnimationFrame (raf) method to GSAP's ticker
// This ensures Lenis's smooth scroll animation updates on each GSAP tick
gsap.ticker.add((time) => {
	lenis.raf(time * 1000); // Convert time from seconds to milliseconds
});

// Disable lag smoothing in GSAP to prevent any delay in scroll animations
gsap.ticker.lagSmoothing(0);


/**
 * Pin Soluzione Dettaglio Intro image
 * @type {Element}
 */
let intro1 = document.querySelector('.home .intro');

if (intro1 !== null)
{
	// pin background image
	ScrollTrigger.create({
		trigger:       intro1,
		pin:           intro1.querySelector('.intro__bg'),
		start:         'top top',
		end:           'bottom top',
		anticipatePin: 1,
		//markers:     true
	});

	// fade in dark backgrounds
	gsap.to('.intro__bg', {
		'--opacity-before': 0,
		'--opacity-after':  1,
		scrollTrigger:      {
			trigger: intro1,
			start:   'top top',
			end:     '+=50%',
			scrub:   1,
			//markers:     true
		}
	});
}

gsap.utils.toArray('.home .intro-2').forEach(function (container, index) {
	let bg      = container.querySelector('[class*="__bg"]');
	let content = container.querySelector('[class*="__content"]');

	// pin background image
	ScrollTrigger.create({
		trigger:       container,
		pin:           bg,
		start:         'top top',
		end:           'bottom top',
		anticipatePin: 1
		//markers:     true
	});

	// fade in dark backgrounds
	gsap.to(bg, {
		'--opacity-before': 1,
		'--opacity-after':  1,
		scrollTrigger:      {
			trigger: container,
			start:   'top top',
			end:     '+=50%',
			scrub:   1,
			//markers:     true
		}
	});

	// text animation
	let tl = gsap.timeline({
		scrollTrigger: {
			trigger: container,
			start:   'top top',
			end:     'bottom top',
			scrub:   1,
			//markers:     true
		}
	});

	tl
		.to(content, {opacity: 1, y: 0, duration: 0.6})       // enter
		.to(content, {opacity: 0})   // leave

});

let parallax = document.getElementById('parallax');

gsap.from(parallax, {
	y: 64,
	scrollTrigger: {
		trigger:  parallax,
		start:    'top 90%',
		end:      '+=70%',
		scrub: 1,
		//markers:     true
	}
});

let parallax2 = document.getElementById('parallax-2');

gsap.from(parallax2, {
	y: 128,
	scrollTrigger: {
		trigger:  parallax2,
		start:    'top 90%',
		end:      '+=70%',
		scrub: 1,
		//markers:     true
	}
});


gsapMatchmedia.add({
	isDesktop: '(min-width: 992px)',
	isTablet:  '(min-width: 768px) and (max-width: 991.98px)',
	isMobile:  '(max-width: 767.98px)'

}, (context) => {

	let {isDesktop, isTablet, isMobile} = context.conditions;

	let section4 = document.querySelector('.section-4');

	gsap.to(section4, {
		opacity: 0,
		y: 200,
		scrollTrigger: {
			trigger:  section4,
			start:    'top 10%',
			end:      '+=50%',
			scrub: 1,
			//markers:     true
		}
	});

	gsap.utils.toArray('.section-5 .col:nth-child(3) img').forEach(function (bottle, index) {

		gsap.from(bottle, {
			y: isDesktop? -520 : -280,
			scale: isDesktop? 1.73 : 2.37,
			scrollTrigger: {
				trigger:  section4,
				start:    'top 10%',
				end:      '+=50%',
				scrub: 1,
				//markers:     true
			}
		});

	});

	/*gsap.from('.section-5 .col:nth-child(3) svg', {
		x: '-50%',
		scale: isDesktop? 1 : 2.37,
		scrollTrigger: {
			trigger:  section4,
			start:    'top 10%',
			end:      '50px',
			scrub: 1,
			//markers:     true
		}
	});*/

});


/**
* Show legal consent wall, close wall on click
*/
let legalConsentWall = document.getElementById('welcome-legal')
let legalConsentBtn = document.getElementById('confirm-welcome-legal')
let legalConsent = sessionStorage.getItem('legalConsent');

if (legalConsent !== '1')
{
	legalConsentWall.style.display = 'block';
	document.body.setAttribute('data-lenis-prevent', 'true');
	document.body.classList.add('popup-open');
}
else
{
	legalConsentWall.remove();
}


legalConsentBtn.addEventListener('click', function (event) {

	sessionStorage.setItem('legalConsent', '1');

	legalConsentWall.classList.add('hide');

	setTimeout(function () {
		legalConsentWall.remove();
		document.body.removeAttribute('data-lenis-prevent');
		document.body.classList.remove('popup-open');
	}, 900);

}, false);



/**
 * ON LOAD
 */

window.addEventListener('load', function (event) {


});



/**
 * ON RESIZE
 */

window.addEventListener('resize', function (event) {
	if (event.target === window)
	{
		// trigger resizeEnd event only when the window resizing ends
		if (this.resizeTO)
		{
			clearTimeout(this.resizeTO);
		}
		this.resizeTO = setTimeout(function () {
			const event = new CustomEvent('resizeEnd');
			window.dispatchEvent(event);

			let windowWidth = window.innerWidth;

			if (windowWidth !== prevWindowWidth)
			{
				prevWindowWidth = windowWidth;

				const eventH = new CustomEvent('resizeHorizontallyEnd');
				window.dispatchEvent(eventH);
			}
		}, 200);
	}
});



/**
 * ON RESIZE END
 */

// do something when the window resizing ends
window.addEventListener('resizeEnd', function () {

	//ScrollTrigger.getAll().forEach(t => t.refresh());
});

window.addEventListener('resizeHorizontallyEnd', function () {

	ScrollTrigger.getAll().forEach(t => t.refresh());

});




/**
 * ON SCROLL
 */
/*window.addEventListener('scroll', function() {
});*/