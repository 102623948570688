//================================= CUSTOMIZE CHECKBOXES AND RADIO BUTTONS LOOK & FEEL
import {utils} from "./utils";
export function inputToToggle(selector) {

	const items = document.querySelectorAll(selector);

	items.forEach((el) => {
		let toggleInput = el;

		let toggleInputname = el.getAttribute('name');
		let toggleInputType = (typeof el.getAttribute('data-type') === 'string')? el.getAttribute('data-type') : el.getAttribute('type');

		let toggleControl = document.createElement('span');
		toggleControl.classList.add('toggle-control', 'toggle-control--' + toggleInputType);

		// manage Gravity forms inputs
		if (toggleInput.closest('div').classList.contains('ginput_container'))
		{
			// move toggleControl inside label if the field is inside a Gravity Forms container
			let toggleLabel = utils.getSiblings(toggleInput).filter(elem => elem.matches("label"));
			toggleLabel[0].prepend(toggleControl);
		}
		else
		{
			toggleInput.after(toggleControl);
		}

		toggleInput.style.display = 'none';

		// init state
		if (toggleInput.checked)
		{
			toggleControl.classList.add('toggle-control--active');
		}
		else
		{
			toggleControl.classList.remove('toggle-control--active');
		}

		// change state
		toggleInput.addEventListener('change', function (e) {
			e.stopPropagation();

			// remove the active state from the other radio inputs with the same name
			if (toggleInputType === 'radio')
			{
				let siblingInputs = document.querySelectorAll('input[name="' + toggleInputname + '"]');

				siblingInputs.forEach((input) => {
					input.nextElementSibling.classList.remove('toggle-control--active')
				});
			}

			if (toggleInput.checked)
			{
				toggleControl.classList.add('toggle-control--active');
			}
			else
			{
				toggleControl.classList.remove('toggle-control--active');
			}
		});
	});
}

inputToToggle('input[type=checkbox].toggle, input[type=radio].toggle, .gfield.toggle input[type=checkbox], input[name="_mc4wp_subscribe_contact-form-7"][type="checkbox"]');
