//================================= GO TO TOP

import {goToByScroll} from './go-to-by-scroll';

let go_to_top = document.querySelectorAll('.scroll-to-top');

go_to_top.forEach(function (item) {

	item.addEventListener('click', function () {
		goToByScroll(document.body, 0, 600);
	});

});


